import dynamic from 'next/dynamic';
import { Section } from 'ui/sections/section';
import { CustomLink } from 'ui/components/CustomLink';
import { Button } from 'ui/components/Button';
// import styles from './NotFoundSection.module.scss';

const DynamicNotFoundAnimation = dynamic(
  () => import('../Animations/Notfound/NotFoundAnimation'),
  { ssr: false },
);

const NotFoundSection = () => (
  <Section pad="sm">
    <div className="[ flex flex-col items-center justify-center ][ py-8 md:py-20 ]">
      <h1 className="[ text-28 font-medium leading-tight tracking-tight ]">Oops, Turing couldn&apos;t decrypt this page</h1>
      <p>
        It looks like the page you were looking for isn&apos;t here anymore.
      </p>
      <Button href="/" outline={true} className="[ border-gray-0 text-gray-0 ][ rounded-lg ][ mt-4 md:mt-10 ]">Back to home</Button>

      <div className="[ relative ][ w-full [&_canvas]:!w-full [&_canvas]:!h-auto [&_canvas]:object-fit ][ mt-10 md:mt-16 lg:mt-20 ]">
        <DynamicNotFoundAnimation />
      </div>
    </div>
  </Section>
);

export default NotFoundSection;
