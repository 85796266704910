
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Head from 'next/head';
import NotFoundSection from 'ui/components/NotFoundSection/NotFoundSection';
function CustomerError() {
    return (<>
      <Head>
        <title>Page not found | Turing</title>
      </Head>
      <NotFoundSection />
    </>);
}
export default CustomerError;

    async function __Next_Translate__getStaticProps__19547f38db8__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19547f38db8__ as getStaticProps }
  